import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Section from '../Section';

export function HomeDifferenceSection(): JSX.Element {
  return (
    <Section className='home-difference'>
      <Row className='justify-content-center'>
        <Col xs={12} md={8} className='text-center'>
          <h2>Powered by Caring</h2>
        </Col>
      </Row>
      <Row>
        <p>
          Your home will receive the highest level of care from our fully
          licensed, bonded, and insured teams. We offer customizable services to
          meet your specific needs, whether you desire cleaning for just a few
          rooms on a budget or are looking for a more comprehensive cleaning
          experience. In addition to providing the right package for you, we are
          committed to sending only our trained cleaning professionals, who are
          passionate about their work and workplace, to your home. Two Maids
          fosters a culture of compassion, interconnectedness, and grit,
          creating a welcoming environment akin to home. We not only prioritize
          care within our internal staff, but also extend our support to
          communities through partnerships with Cleaning For A Reason, The
          Finley Project, and Alliance For Hope.
        </p>
      </Row>
      <div className='powered-by-caring-image-container'>
        <a
          href='https://cleaningforareason.org/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            className='powered-by-caring-image'
            src='https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682368766/hubsite-reskin/cleaning_for_a_reason.webp'
            alt='Cleaning For a Reason'
          />
        </a>
        <a
          href='https://www.thefinleyproject.org/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            className='powered-by-caring-image'
            src='https://res.cloudinary.com/twomaidsengi/image/upload/v1713540352/hubsite-reskin/TheFinleyProject.webp'
            alt='The Finley Project'
          />
        </a>
        <a
          href='https://www.allianceforhope.com/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            className='powered-by-caring-image'
            src='https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682368766/hubsite-reskin/alliance_for_hope.webp'
            alt='Alliance For Hope'
          />
        </a>
      </div>
    </Section>
  );
}
