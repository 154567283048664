import React from 'react';
import { Col } from 'react-bootstrap';
import Section from '../Section';

export function RotatingTextSection(): JSX.Element {
  return (
    <Section className='rotating-text-section'>
      <Col>
        <h1 className='h2'>
          Your day just <br className='rotating-mobile-break' />
          got a whole lot <br className='rotating-mobile-break' />
          <div className='rotating-words'>
            <span>better.</span>
            <span>brighter.</span>
            <span>sunnier.</span>
            <span>more delightful.</span>
            <span>more fulfilling.</span>
          </div>
        </h1>
        <p>
          Let Two Maids do all of the dirty work for you. Focus on everything
          else you need to get done and schedule a home cleaning service with us
          today.
        </p>
      </Col>
    </Section>
  );
}
