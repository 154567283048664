/**
 * Animate review stars
 */
import ScrollOut from 'scroll-out';

const animateStars = () => {
  const starsTrigger = document.querySelectorAll('.review-stars__trigger');

  if (!starsTrigger) {
    return;
  }

  starsTrigger.forEach((el) => {
    ScrollOut({
      targets: el,
      once: true,
      onShown: (element) => {
        const starsWrap = element.querySelectorAll('.review-stars__wrap');

        if (!starsWrap) {
          return;
        }

        starsWrap.forEach((el) => {
          const stars = el.querySelectorAll('span');

          if (!stars) {
            return;
          }

          stars.forEach((star, index) => {
            setTimeout(() => {
              star.classList.add('cycle-in');
            }, 125 * index);
          });
        });
      },
    });
  });
};

export default animateStars;
