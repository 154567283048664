import * as Sentry from '@sentry/node';
import camelcaseKeys from 'camelcase-keys';
import { Duration } from 'luxon';
import { GetStaticPaths, GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import Head from '../../components/head';
import Hero from '../../components/Hero';
import Layout from '../../components/Layout';
import OpenGraphDescription from '../../components/OpenGraphDescription';
import OpenGraphTitle from '../../components/OpenGraphTitle';
import { animateStars } from '../../components/ReviewStars';
import Section from '../../components/Section';
import { HomeDifferenceSection } from '../../components/Sections/HomeDifferenceSection';
import { RotatingTextSection } from '../../components/Sections/RotatingTextSection';
import { FranchiseeProvider } from '../../context/Franchisee';
import { FranchiseeState } from '../../context/Franchisee/franchisee.types';
import franchiseeSession from '../../context/Franchisee/franchiseeSession';
import Gataware from '../../SDKs/Gataware';
import GatherUp from '../../SDKs/GatherUp';

const ReviewsCarousel = dynamic(
  () => import('../../components/ReviewsCarousel'),
  { ssr: false }
);

const FeaturedInSection = dynamic(
  () => import('../../components/FeaturedInSection'),
  { ssr: false }
);

export const getStaticPaths: GetStaticPaths = async () => {
  const response = await Gataware.getLocations()
    .then(({ data }) => JSON.parse(data))
    .then((data) => data.data.map((franchisee) => camelcaseKeys(franchisee)));

  const paths = await response.map(({ slug }) => ({
    params: { franchiseeSlug: slug },
  }));

  return {
    paths,
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const { franchiseeSlug } = params;

  const response = await Gataware.getLocations({
    slugs: [franchiseeSlug.toLocaleString()],
  }).then(({ data }) => JSON.parse(data));

  const franchisees = await response.data
    ?.filter(({ slug }) => franchiseeSlug === slug)
    .map((franchisee) => camelcaseKeys(franchisee));

  if (!franchisees) {
    return {
      notFound: true,
      revalidate: Duration.fromObject({ hours: 8 }).as('seconds'),
    };
  }

  const [franchisee] = franchisees;

  if (!franchisee) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  const url = franchisee.url + '.com';
  
  // Gets the businesses and finds the businessWebsiteURL that matches the franchisee url
  const businessesId = await GatherUp.getBusinesses()
    .then((res) => {
      if (res === null) return;
      const matchBusinessWithFranchisee = res.find(
        (business) =>
          business.businessWebsiteURL.replace(/(^\w+:|^)\/\//, '').toLowerCase() === url.toLowerCase()
      );

      if (matchBusinessWithFranchisee === undefined) {
        return null;
      }

      let businessId = matchBusinessWithFranchisee.businessId;

      return businessId;
    })
    .catch((error) => {
      Sentry.captureException(error);
    });

  // Passes the matched businessesId to get the reviews for the current location
  const reviews = await GatherUp.getReviews({
    businessId: businessesId,
  });

  return {
    props: {
      franchisee,
      reviews,
      businessesId,
    },
    revalidate: Duration.fromObject({ hours: 8 }).as('seconds'),
  };
};

interface SpecificStorePageProps {
  /* add specific store page props here */
  franchisee: FranchiseeState;
  reviews;
  businessesId;
}

const SpecificStore: React.FC<SpecificStorePageProps> = ({
  franchisee,
  reviews,
  businessesId,
}: SpecificStorePageProps) => {
  useEffect(animateStars);
  useEffect(() => {
    franchiseeSession.set(franchisee.id);
  });

  return (
    <FranchiseeProvider franchiseeValue={franchisee}>
      <OpenGraphTitle title={`Professional House Cleaning Services | ${franchisee.name}`} />
      <OpenGraphDescription>
        {/* Add Specific Store description here */}
        Two Maids {franchisee.name}
      </OpenGraphDescription>
      <Layout>
        <Head slug='' title={`Professional House Cleaning Services | ${franchisee.name}`} />
        <OpenGraphTitle title={`Professional House Cleaning Services | ${franchisee.name}`} />
        <OpenGraphDescription>
          {/* Add About description here */}
          Two Maids {franchisee.name} provides residential house cleaning
          services.
        </OpenGraphDescription>

        <Hero />

        <RotatingTextSection />

        <Section className='house-cleaning-experts-section'>
          <Col>
            <h2>Your Resident Home Cleaning Expert</h2>
            <p>
              At Two Maids, our purpose is to make a difference in people's
              daily lives. With our maid service, we help busy families maintain
              beautiful, clean, and healthy homes by taking care of the chores
              that no one has the time or energy to do. We proudly serve our
              customers, putting their needs ahead of our own so they can focus
              on what matters most to them. As the best house cleaning service
              on the market, we pride ourselves on care, quality, and a
              sparkling home cleaning. Everything we do makes lives a little
              easier and homes a little brighter.
            </p>
          </Col>
          <Row className='justify-content-between home-services-container'>
            <Col
              xs={12}
              md={4}
              className='home-services__service px-4 mb-5 mb-md-0'
            >
              <img
                src='https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,f_auto,q_auto,w_300/v1670257150/hubsite-reskin/clipboard.png'
                alt='A Clipboard'
                width='100%'
                height='100%'
                loading='lazy'
              />
              <h3>WE SUPPLY EVERYTHING FOR YOUR CLEANING NEEDS</h3>
              <p>
                There's not a standard cleaning product we don't supply. Two
                Maids {franchisee.name} provides all the cleaning supplies and
                equipment needed to clean your home. Be it a one-time deep
                clean, or a recurring cleaning, we bring the cleaning supplies
                so that you can have one less thing to worry about.
              </p>
            </Col>
            <Col
              xs={12}
              md={4}
              className='home-services__service px-4 mb-5 mb-md-0'
            >
              <img
                src='https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,f_auto,q_auto,w_300/v1670257150/hubsite-reskin/okay-hand.webp'
                alt='A-okay Hand'
                width='100%'
                height='100%'
                loading='lazy'
              />
              <h3>EXPERIENCED HOUSE CLEANERS</h3>
              <p>
                Our team of experienced house cleaners undergoes thorough
                training, background checks, and is fully bonded and insured.
                They bring professionalism, efficiency, and a friendly demeanor
                to every cleaning job, ensuring your home is sparkling clean and
                welcoming.
              </p>
            </Col>
            <Col xs={12} md={4} className='home-services__service px-4'>
              <img
                src='https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,f_auto,q_auto,w_300/v1670257150/hubsite-reskin/smile-badge.webp'
                alt='A badge with a smile'
                width='100%'
                height='100%'
                loading='lazy'
              />
              <h3>PROVEN HISTORY IN THE HOUSE CLEANING SERVICE</h3>
              <p>
                We are a multi-award-winning cleaning company that is over 15
                years in the making. Trusted by over 500 households daily
                nationwide, our track record speaks for itself. We take pride in
                delivering consistent, reliable cleaning services tailored to
                meet your needs.
              </p>
            </Col>
            <Link href='/services/'>
              <a className='btn btn-primary services-btn'>VIEW OUR SERVICES</a>
            </Link>
          </Row>
        </Section>

        <HomeDifferenceSection />

        <Section lg={11} className='reviews-carousel-container'>
          <Row className='justify-content-center'>
            <Col xs={12} md={8} className='text-center'>
              <h2>A Few Kind Words</h2>
            </Col>
          </Row>
          <LazyLoad once>
            {businessesId === null ||
            reviews[0] === null ||
            reviews.length === 0 ? (
              <ReviewsCarousel />
            ) : (
              <ReviewsCarousel
                storeReviews={reviews}
                location={`${franchisee.name}, ${franchisee.state}`}
              />
            )}
          </LazyLoad>
          <div className='text-center'>
            <Link
              href={`/${
                franchisee.id > -1 ? `${franchisee.slug}/reviews` : '/'
              }`}
            >
              <a className='btn btn-primary dark-bg-btn'>READ MORE REVIEWS</a>
            </Link>
          </div>
        </Section>
        <FeaturedInSection />
      </Layout>
    </FranchiseeProvider>
  );
};

export default SpecificStore; 