/**
 * Create a page section with different background colors, vertical padding and widths
 * Defaults to transparent, top and bottom, col-xs-12/col-md-11/col-lg-10
 */
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
// import style from './Section.module.scss';

interface SectionProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  padding?: string;
  xs?: number;
  md?: number;
  lg?: number;
}

const Section: React.FC<SectionProps> = ({
  xs,
  md,
  lg,
  color,
  className,
  children,
  padding,
}: SectionProps) => {
  const sectionColor: string = color || 'transparent';
  const sectionXs: number = xs || 12;
  const sectionMd: number = md || 11;
  const sectionLg: number = lg || 10;
  let sectionPadding: string | boolean = 'y';

  if (padding === 'top') {
    sectionPadding = 't';
  } else if (padding === 'bottom') {
    sectionPadding = 'b';
  } else if (padding === 'none' || padding === null) {
    sectionPadding = false;
  }

  return (
    <section
      className={`bg-${sectionColor} ${sectionPadding ? `p${sectionPadding}-5` : ''
        } ${className || ''}`}
    >
      {children && (
        <Container>
          <Row className="justify-content-center">
            <Col xs={sectionXs} md={sectionMd} lg={sectionLg}>
              {children || null}
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default Section;
