import React from 'react';
import Head from 'next/head';

interface OpenGraphDescriptionProps {
  // add open graph description props here
  children: React.ReactNode;
}

const OpenGraphDescription: React.FC<OpenGraphDescriptionProps> = ({
  children,
}: OpenGraphDescriptionProps) => {
  const description = children.toString();

  return (
    <Head>
      {/* Add open graph description Content Here */}
      <meta name='description' key='description' content={description} />
      <meta
        name='og:description'
        property='og:description'
        key='og:description'
        content={description}
      />
      <meta
        name='twitter:description'
        key='description'
        content={description}
      />
    </Head>
  );
};

export default OpenGraphDescription;
