import React from 'react';
import Head from 'next/head';

interface OpenGraphTitleProps{
  title: string;
}

const OpenGraphTitle: React.FC<OpenGraphTitleProps> = ({ title }: OpenGraphTitleProps) => (
  <Head>
    <title key="title">{title}</title>
    <meta name="og:title" property="og:title" key="og:title" content={title} />
    <meta name="twitter:title" key="twitter:title" content={title} />

  </Head>
);

export default OpenGraphTitle;
