import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { blue400 } from '../../ui/colors';
import animateStars from './animateStars';

export interface ReviewStarsProps{
  // add review stars props here
  className?: string;
  color?: string;
  size?: SizeProp;

}

const ReviewStars: React.FC<ReviewStarsProps> = ({ className, color, size }: ReviewStarsProps) => (
  <div
    className={`review-stars__wrap text-nowrap ${className || ''}`}
  >
    <span>
      <FontAwesomeIcon icon={faStar} color={color} size={size} />
    </span>
    {' '}
    <span>
      <FontAwesomeIcon icon={faStar} color={color} size={size} />
    </span>
    {' '}
    <span>
      <FontAwesomeIcon icon={faStar} color={color} size={size} />
    </span>
    {' '}
    <span>
      <FontAwesomeIcon icon={faStar} color={color} size={size} />
    </span>
    {' '}
    <span>
      <FontAwesomeIcon icon={faStar} color={color} size={size} />
    </span>
  </div>
);

ReviewStars.defaultProps = {
  className: null,
  color: blue400,
  size: '1x',
};

export { ReviewStars as default, animateStars };
