/**
 * SEO component that sets up the <head> and page metadata.
 *
 * @link https://github.com/nfl/react-helmet
 */
import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { pink500 } from '../ui/colors';

const colorTheme = pink500;

function Head({ slug, description, lang, meta, title }) {
  const metaDescription = description || 'Meta';
  // const metaDescription = 'Meta';
  datadogRum.init({
    applicationId: '2b8a7178-88fd-4eae-b1fc-d466adbcda4c',
    clientToken: 'puba6170b123e376449351616f417bffbae',
    site: 'datadoghq.com',
    service: 'hubsite',

    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    silentMultipleInit: true,
  });

  datadogRum.startSessionReplayRecording();
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: slug,
      }}
      title={title}
      titleTemplate={`${title}`}
      link={[
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,600i,700,700i&display=swap',
        },
        {
          rel: 'manifest',
          href: '/manifest.webmanifest',
        },
        // {
        //   rel: `stylesheet`,
        //   href: `/ui/styles/app.css`,
        // },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/favicons/apple-touch-icon.png',
        },
        {
          rel: 'icon',
          sizes: '32x32',
          href: '/favicons/favicon-32x32.png',
        },
        {
          rel: 'icon',
          sizes: '16x16',
          href: '/favicons/favicon-16x16.png',
        },

        {
          rel: 'mask-icon',
          href: '/favicons/safari-pinned-tab.svg',
          color: colorTheme,
        },
      ]}
      meta={[
        {
          name: 'apple-mobile-web-app-title',
          content: 'Two Maids ',
        },
        {
          name: 'application-name',
          content: 'Two Maids ',
        },
        {
          name: 'msapplication-TileColor',
          content: colorTheme,
        },
        {
          name: 'theme-color',
          content: colorTheme,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {/* <script
        async
        defer
        src='https://tools.luckyorange.com/core/lo.js?site-id=e4cb63f5'
      /> */}
      {/* <script type='text/javascript'>
        var _stk = "7ec2e7a246a3c3a681a7ddd9293b222b22eefd01"; var a=document,
        b=a.createElement("script"); b.type="text/javascript"; b.async=!0;
        b.src=('https:'==document.location.protocol ? 'https://' : 'http://') +
        'd31y97ze264gaa.cloudfront.net/assets/st/js/st.js';
        a=a.getElementsByTagName("script")[0]; a.parentNode.insertBefore(b,a);
      </script> */}
    </Helmet>
  );
}

Head.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Head;
